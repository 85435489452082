import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, radius, COLOR_CONSTANTS, fontSizes } from 'theme'
import { Flex, Box } from 'components/Layout'
import { H2, Text } from 'components/Typography'
import Button from 'components/Button'

const Wrapper = styled(Flex)`
  display: flex;
  flex-direction: column;
  padding: ${space.l};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  border-radius: ${radius.m};
  box-shadow: 0px 20px 40px rgba(39, 40, 49, 0.05);
  position: relative;
`

const H2Styled = styled(H2)`
  font-size: ${fontSizes.xxl};
`

const Item = ({ type, position, description, onClick }) => (
  <Wrapper>
    <Text color="secondaryText">{type}</Text>
    <Box my="m" minHeight={{ desktop: '64px' }}>
      <H2Styled color={COLOR_CONSTANTS.DENIM}>{position}</H2Styled>
    </Box>
    <Text color="secondaryText" mb="l">
      {description}
    </Text>
    <Flex maxWidth="200px" alignSelf="center" position="absolute" bottom={space.m}>
      <Button.Gradient onClick={onClick}>Apply Now</Button.Gradient>
    </Flex>
  </Wrapper>
)

Item.propTypes = {
  type: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Item

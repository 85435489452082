import React from 'react'
import styled from 'styled-components'
import { space as styledSpace } from 'styled-system'
import PropTypes from 'prop-types'
import { transparentize } from 'polished'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import { space, radius, COLOR_CONSTANTS } from 'theme'
import { Box } from 'components/Layout'

const StyledDialogOverlay = styled(DialogOverlay)`
  background-color: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
  z-index: 2147483001;
`

const StyledDialogContent = styled(DialogContent)`
  position: relative;
  max-width: ${({ maxwidth }) => maxwidth};
  width: 100% !important;
  border-radius: ${radius.s};
  ${styledSpace};
  padding: 0 !important;
  margin: 0 auto !important;
  transform: translate(0, ${({ translatetop }) => translatetop || 50}%);
`

const Modal = ({ isOpen, handleDismiss, children, maxwidth, ...props }) => (
  <StyledDialogOverlay isOpen={isOpen} onDismiss={handleDismiss}>
    <Box m="0 auto" width={['90vw', '70vw']}>
      <StyledDialogContent aria-label="modal" maxwidth={maxwidth} {...props}>
        {children}
      </StyledDialogContent>
    </Box>
  </StyledDialogOverlay>
)

Modal.defaultProps = {
  maxwidth: '450px',
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  handleDismiss: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  maxwidth: PropTypes.string,
}

export default Modal
